<template>
  <div class="site-sideMenu">
     <el-menu
        :default-active="menuActiveName || 'home'"
        class="site-sidebar__menu">
        <sub-menu
          v-for="menu in secondMenuList"
          :key="menu.id"
          :menu="menu">
        </sub-menu>
      </el-menu>
  </div>
</template>
<script>
import SubMenu from './main-menuItem'
import { mapState } from "vuex";
export default {
    components:{
        SubMenu
    },
    computed:{
      ...mapState("common", { 
      secondMenuList: (state) => state.secondMenuList,
      menuList: (state) => state.menuList,
      mainTabs: (state) => state.mainTabs,
      AllmenuList:(state) => state.AllmenuList,
      menuActiveName:(state) => state.menuActiveName,
      mainTabsActiveName:(state) => state.mainTabsActiveName,
      }),
    }
}
</script>