<template>
  <el-submenu
    v-if="menu.children && menu.children.length >= 1"
    :index="menu.id + ''">
    <template slot="title">
      <span>{{ menu.name }}</span>
    </template>
    <sub-menu
      v-for="item in menu.children"
      :key="item.id"
      :menu="item">
    </sub-menu>
  </el-submenu>
  <div v-else>
  <el-menu-item v-if="menu.isButton === '0' && menu.isShow === '1'" :index="menu.id + ''" @click="gotoRouteHandle(menu)">
    <span>{{ menu.name }}</span>
  </el-menu-item>
  </div>
</template>
<script>
import SubMenu from './main-menuItem'
export default {
    name: 'sub-menu',
    data(){
        return{}
    },
    props: {
      menu: {
        type: Object,
        required: true
      }
    },
    components: {
      SubMenu
    },
    methods:{
      gotoRouteHandle(menu){
        // 避免点击重复路由
        if (this.$route.path !== '/' + menu.url) {
          this.$router.push({path: '/' + menu.url})
        } 
      }
    }
}
</script>